import React, { useEffect, useRef, useState } from 'react'
import easee from 'lib/easee'
import { useAuth } from 'auth'
import * as PropTypes from 'prop-types'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Papa from 'papaparse'
import { addEnergyCosts, getSpotPrices, toBought } from 'lib/chargingCosts'
import download from 'lib/download'
import summerize from 'lib/summarize'
import parsePeriod from 'lib/parsePeriod'

export default function AdminChargingCosts() {
  const { site } = useOutletContext()
  const navigate = useNavigate()
  const { token } = useAuth()
  const { period } = useParams()
  const [loading, setLoading] = useState(true)
  const [chargers, setChargers] = useState(null)
  const shouldFetch = useRef(true)
  const p = parsePeriod(period)

  useEffect(() => {
    // Avoid running the effect twice in strict mode: https://www.youtube.com/watch?v=MXSuOR2yRvQ
    if (!shouldFetch.current) {
      return
    }
    shouldFetch.current = false

    if (!p.isValid) {
      return navigate('/admin')
    }

    async function fetchData() {
      // Get spotPrices and the chargers that have been active during the period
      // We could use the summary report to cross-check the detailed data fetched to verify
      // it's correct…
      const [spotPrices, chargerSummary] = await Promise.all([
        getSpotPrices(p.from, p.to),
        easee.chargerSummaryReport(token, 35296, p.from, p.to),
      ])

      let updatedChargers = chargerSummary.map(charger => {
        return { ...charger, loading: true }
      })
      setChargers(updatedChargers)
      setLoading(false)

      // Get detailed hourly energy usage for these chargers
      // For doing this in parallel with limited concurrency: https://stackoverflow.com/a/51020535/293198
      for (let i = 0; i < updatedChargers.length; i++) {
        updatedChargers[i].lifeTimeEnergyHourly = await easee.chargerLifeTimeEnergyHourly(token, updatedChargers[i].id, p.from, p.to)
        updatedChargers[i].loading = false
        setChargers(updatedChargers.concat([]))
      }

      // Add all the costs
      setChargers(updatedChargers.map(charger => {
        return { ...charger, data: addEnergyCosts(toBought(charger.lifeTimeEnergyHourly), spotPrices) }
      }))
    }

    fetchData()
  }, [navigate, token, p])

  return <>
    <h2>Fakturaunderlag</h2>
    <p><Link to={`/site/${site.id}/admin`}>Tillbaka</Link></p>
    {!p.complete && <p>Varning, perioden har inte avslutats ännu så ladda inte ner underlag för fakturering än!</p>}
    {loading && <p>Laddar…</p>}
    {chargers && <Chargers chargers={chargers} period={period} />}
  </>
}

function Chargers({ chargers, period }) {
  const downloadSummaryCsv = () => {
    const data = chargers.flatMap(charger => {
      return charger.data
        // We probably only want to include rows where charging has happened
        // but during development it may be good to see all data
		.filter(row => {
			return row.chargedKwh > 0
		})
        .map(row => {
          return Object.assign({}, row, {
            chargerId: charger.id,
            chargerName: charger.name,
          })
        })
    })
    download(Papa.unparse(summerize(data, period)), 'text/csv', `charging-${period}-summary.csv`)
  }

  const downloadDetailedCsv = () => {
    const data = chargers.flatMap(charger => {
      return charger.data
        // We probably only want to include rows where charging has happened
        // but during development it may be good to see all data
        .filter(row => {
           return row.chargedKwh > 0
        })
        .map(row => {
          return Object.assign({}, row, {
            chargerId: charger.id,
            chargerName: charger.name,
          })
        })
    })
    download(Papa.unparse(data), 'text/csv', `charging-${period}-detailed.csv`)
  }

  const stillLoading = chargers.some(charger => charger.loading)
  return <>
    <ul>
      {chargers.map(charger => {
        return <li key={charger.id}>{charger.name} {charger.loading && 'Laddar…'}</li>
      })}
    </ul>
    {!stillLoading && <button onClick={downloadSummaryCsv}>Ladda ner summering, CSV</button>}
    {!stillLoading && <button onClick={downloadDetailedCsv}>Ladda ner detaljerad, CSV</button>}
  </>
}

Chargers.propTypes = { chargers: PropTypes.any }

