import { DateTime } from 'luxon'

function get(area, from, to) {
  return fetch(`https://nordpool.kitbuilder.se/api.php?area=${area}&from=${encodeDate(from)}&to=${encodeDate(to)}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  })
    .then(res => res.json())
    // Convert dates returned from server to same format as used internally
    .then(spotPrices => {
      return Object.fromEntries(Object.entries(spotPrices).map(([k, v]) => {
        const utcDate = DateTime
          .fromISO(k)
          .toUTC()
          .toISO()
        return [utcDate, v]
      }))
    })
}

function encodeDate(from) {
  return encodeURIComponent(from.toISO({ suppressMilliseconds: true }))
}

const spotPrices = {
  get,
}

export default spotPrices
