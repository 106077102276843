import { parseChargerSummaryReport } from './easeeReports'

function login(username, password) {
  return easeeRequest('POST', '/accounts/login', { body: JSON.stringify({ username, password }) })
}

function profile(accessToken) {
  return easeeRequest('GET', '/accounts/profile', { accessToken })
}

function products(accessToken) {
  return easeeRequest('GET', '/accounts/products', { accessToken })
}

function chargerSummaryReport(accessToken, siteId, from, to) {
  const category = 2 // Charger Summary CSV
  return easeeRequest('GET', `/sessions/export/${siteId}/${category}/${(encodeDate(from))}/${encodeDate(to)}`, { accessToken })
    .then(parseChargerSummaryReport)
}

// Returns data like this (times in UTC)
// [
//   {
//     "year": 2022,
//     "month": 8,
//     "day": 31,
//     "hour": 22,
//     "consumption": 0,
//     "date": "2022-08-31T22:00:00+00:00"
//   },
//   …
// ]
function chargerLifeTimeEnergyHourly(accessToken, chargerId, from, to) {
  return easeeRequest('GET', `/chargers/lifetime-energy/${chargerId}/hourly?from=${encodeDate(from)}&to=${encodeDate(to)}&tz=UTC`, { accessToken, accept: 'text/csv' })
}

function easeeRequest(method, path, options = {}) {
  const fetchOptions = {
    method: method,
    headers: {
      'Accept': options?.accept || 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }
  if (options?.accessToken) {
    fetchOptions.headers['Authorization'] = `Bearer ${options.accessToken}`
  }
  if (options?.body) {
    fetchOptions.body = options.body
    fetchOptions.headers['Content-Type'] = 'application/*+json'
  }

  return fetch(`https://api.easee.cloud/api${path}`, fetchOptions)
    .then(res => {
      if (res.headers.get('Content-Type').match(/json/)) {
        return res.json()
      }
      return res.text()
    })
    .then(data => {
      if (data.errorCode) {
        throw Object.assign(new Error(data.title), data)
      }
      return data
    })
}

function encodeDate(from) {
  return encodeURIComponent(from.toISO())
}

const easee = {
  login,
  profile,
  products,
  chargerSummaryReport,
  chargerLifeTimeEnergyHourly,
}

export default easee
