import React, { useEffect, useRef, useState } from 'react'
import easee from 'lib/easee'
import spotPrices from 'energy/spotprices'
import { useAuth } from 'auth'
import { useOutletContext, useParams } from 'react-router-dom'
import { addEnergyCosts, toBought } from 'lib/chargingCosts'
import Totals from 'components/Totals'
import Specification from 'components/Specification'
import ChargerName from 'components/ChargerName'
import parsePeriod from 'lib/parsePeriod'

export default function ChargingCosts() {
  const { token } = useAuth()
  const { charger } = useOutletContext()
  const { chargerid, period } = useParams()
  const [loading, setLoading] = useState(true)
  const [specification, setSpecification] = useState(null)
  const fetchedPeriod = useRef(undefined)
  const p = parsePeriod(period)

  useEffect(() => {
    if (fetchedPeriod.current === p.short) {
      return
    }
    fetchedPeriod.current = p.short

    setLoading(true)
    setSpecification(null)

    Promise.all([
      easee.chargerLifeTimeEnergyHourly(token, chargerid, p.from, p.to),
      spotPrices.get('SE3', p.from, p.to),
    ])
      .then(([lifeTimeEnergyHourly, hourlySpotPrices]) => {
        return addEnergyCosts(toBought(lifeTimeEnergyHourly), hourlySpotPrices)
      })
      .then(specification => {
        setSpecification(specification)
        setLoading(false)
      })
  }, [chargerid, p, token])

  return <>
    <h2>Laddkostnader för <ChargerName charger={charger} />, {p.humanReadable}</h2>
    {loading && <p>Hämtar laddningsdata för {p.humanReadable}…</p>}
    {specification && <Totals specification={specification} period={p} />}
    {specification && <Specification specification={specification} period={p} />}
  </>
}
