// chargerName, chargerId, period, boughtExVat	boughtVat	boughtTotal
export default function summerize(data, period) {
  return Object.values(data.reduce((prev, curr) => {
    prev[curr.chargerId] = prev[curr.chargerId] || {
      chargerName: curr.chargerName,
      chargerId: curr.chargerId,
      period,
      chargedKwh: 0,
      boughtExVat: 0,
      boughtVat: 0,
      boughtTotal: 0,
    }
    prev[curr.chargerId].boughtExVat += curr.boughtExVat
    prev[curr.chargerId].boughtVat += curr.boughtVat
    prev[curr.chargerId].boughtTotal += curr.boughtTotal
    prev[curr.chargerId].chargedKwh += curr.chargedKwh
    return prev
  }, {}))
	.map(entry => {
    const roundKr = (value) => {
      return Math.round(value * 100) / 100.0;
    }
    entry.averageCostPerKwh = roundKr(entry.boughtTotal / entry.chargedKwh)
    entry.chargedKwh = roundKr(entry.chargedKwh,)
    entry.boughtExVat = Math.round(entry.boughtExVat)
    entry.detailText = entry.chargedKwh + "kWh för " + entry.averageCostPerKwh + " kr/kWh"
    return entry
  })
}
